/*! Make sure footer stays at the bottom if not enought content in the page */
.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

/*! PageUnit vender styles */
.PU-image-container {
  overflow: hidden;
}

.PU-image {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

.PU-svg-container {
  display: inline-block;
}
.PU-svg-container svg {
  height: 100%;
  position: relative;
  width: 100%;
}

.PU-input {
  position: relative;
}
.PU-input .input-error {
  bottom: -2rem;
  color: #bd0000;
  display: flex;
  height: 100%;
  left: 0;
  max-height: 2rem;
  position: absolute;
  width: 100%;
}

.lazyloading {
  filter: blur(10px);
  transform: scale(1.1);
}

.lazyloaded {
  transition: transform 100ms ease-in;
}

.cs-flex {
  display: flex;
  position: relative;
}
.cs-grid {
  display: grid;
  position: relative;
}
.cs-img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
  width: 100%;
}
.cs-grid-overlay {
  display: grid;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.cs-grid-overlay__item {
  border: 1px dashed rgba(20, 121, 187, 0.4705882353);
  pointer-events: none;
  position: relative;
}
.cs-link {
  display: block;
}

.cs-19f3a129-5617-4f9a-a036-42fa87efe569 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.cs-8faeb46d-50aa-486a-998b-046a8717b65f {
  color: #222222;
  font-size: 1.25rem;
  letter-spacing: -0.012em;
  line-height: 1.5;
  margin: 0 0 5rem;
}

.XS .cs-8faeb46d-50aa-486a-998b-046a8717b65f {
  font-size: 1rem;
}

.cs-b0571b4f-6d59-4646-93fb-cfaad7a3ffd7 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.cs-5a68a463-4c96-4685-8902-d7c387846d60 {
  margin: 1.6rem 0;
  border-bottom: 2px solid #222222;
  max-width: 70ch;
  width: 100%;
}

.cs-26a57574-0c99-4a0b-844c-025ad3d01236 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 15rem;
  height: 15rem;
  padding: 7rem 0 7rem;
}

.cs-7f71b429-f42f-4f0b-b91a-04a893819dab {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8vh 0 0;
}

.cs-a4c98ce4-6200-4eb4-a582-232c5dbf3f32 .trix-content h1 {
  font-size: 1.563rem;
  margin: 3rem auto 1rem auto;
  line-height: 1.2;
  color: #222222;
  font-weight: bold;
}

.cs-9078621d-7a63-49de-8fdd-93e68001fd7b {
  color: #222222;
}

.cs-9913fda1-d1f8-4e54-b87c-b5687ea3a163 {
  color: #222222;
}

.cs-44c341f7-c83f-4dc5-9a8a-93e43cc52e5d {
  display: none;
}

.cs-44c341f7-c83f-4dc5-9a8a-93e43cc52e5d-marked {
  display: flex;
  color: #222222;
}

.cs-c3658af9-5c4a-426c-8657-3e7bd640161e {
  border-right: 1px solid black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.cs-95047546-7b94-4590-88cd-4afa1a25b301 {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  max-width: 100vw;
}

.cs-e727e2aa-26b7-42b4-b62c-304370f23e83 {
  border-right: 1px solid black;
  width: 6rem;
  min-width: 6rem;
  max-width: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.cs-f91520e7-495c-4b75-b919-7d3f142b152d * {
  line-height: 1.5;
  font-size: 1rem;
}

.cs-43d010e9-c62d-4782-a19a-2029ae9b0578 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cs-38fe58a1-65e5-4beb-b4ce-95a19d28d1a0 {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1411764706);
}

.cs-504bc2a6-4b11-461e-abd8-91c5f13f24e3 {
  display: flex;
  flex-direction: row;
}

.cs-504bc2a6-4b11-461e-abd8-91c5f13f24e3:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.1411764706);
}

.cs-63e3011e-c35f-4cb3-bd1f-335f49b7e83d {
  width: 7rem;
  min-width: 7rem;
  max-width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.cs-d1738d1c-5997-466b-8579-2cd7349ab427 {
  display: flex;
  flex-direction: column;
}

.cs-09cef4d7-7d85-4c58-8507-eacb5572eb75 {
  border-right: 1px dashed black;
  width: 6rem;
  min-width: 6rem;
  max-width: 6rem;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.cs-f941e501-ecc8-4c64-b659-6a5b56e28054 {
  text-align: center;
  color: #222222;
}

.cs-b2866d48-24ea-4833-8eed-cf96dbbf3668 {
  border-right: 1px dashed rgba(0, 0, 0, 0.1411764706);
  width: 11rem;
  min-width: 11rem;
  max-width: 11rem;
  padding: 0.5rem;
  text-align: center;
}

.cs-a1646f85-e282-479f-8b23-2f3b28d254b4 {
  border-right: 1px dashed black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.cs-f773a960-785b-482e-9623-12393c904d21 {
  display: none;
}

.cs-f773a960-785b-482e-9623-12393c904d21-marked {
  display: flex;
  color: #222222;
}

.cs-9b202878-085c-4b10-8975-5e112b801cd7 {
  border-right: 1px dashed black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.cs-5fc0f4e3-31e4-4980-88d2-3ebb622acf08 {
  display: none;
}

.cs-5fc0f4e3-31e4-4980-88d2-3ebb622acf08-marked {
  display: flex;
  color: #222222;
}

.cs-31b3dd90-868a-4a37-a027-27eef3e98aee {
  width: 11rem;
  min-width: 11rem;
  max-width: 11rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.cs-dbde4233-6f5a-42cc-b660-4a76f3f71f90 {
  color: #222222;
}

.cs-aac92581-277b-406a-bd22-0e22e8fdabd6 {
  border-right: 1px dashed black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.cs-d5e5b6e6-3438-49fb-94e3-92da540937c1 {
  display: none;
}

.cs-d5e5b6e6-3438-49fb-94e3-92da540937c1-marked {
  display: flex;
  color: #222222;
}

.cs-89fc1f3e-72da-4284-ada3-548a7ce10b51 {
  color: #222222;
}

.cs-52bd7b19-88ba-42ab-a725-291f15615742 {
  border-right: 1px solid black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.cs-0c295216-340b-436e-a3d8-815e95f86201 {
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  color: #222222;
}

.cs-e5cc67b4-d0f7-4201-882d-2b894c6169f8 {
  border-right: 1px solid black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.cs-98196131-4cbc-4d0c-9346-982bd9d98d4f {
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  color: #222222;
}

.cs-7773a3f3-c79e-4442-b5a1-a9d35b3507cb {
  border-right: 1px solid black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.cs-f1531e7a-cfa3-492c-bcb2-eab71fb34c2c {
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  color: #222222;
}

.cs-cb016020-ca07-4533-98b6-45b7acf18b6f {
  width: 6rem;
  min-width: 6rem;
  max-width: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.cs-d3b9858c-d295-4078-b35d-7a06bb2f5761 {
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
}

.cs-b8aafa73-b92b-4fcb-b44f-7b7f8d88a57c {
  width: 11rem;
  min-width: 11rem;
  max-width: 11rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.cs-711a928c-0475-429a-bb24-7cebae17c712 {
  color: #222222;
}

.cs-773cd985-3f0e-4682-92be-c3bdbfe23942 {
  border-right: 1px dashed rgba(0, 0, 0, 0.1411764706);
  width: 11rem;
  min-width: 11rem;
  max-width: 11rem;
  padding: 0.5rem;
  text-align: center;
}

.cs-06bfb5de-e73c-4e22-a135-619118a2b61a {
  color: #222222;
  font-weight: bold;
}

.cs-ea87b23d-33c4-4baa-b3a8-e07a2f36bd6d {
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  color: #222222;
}

.cs-3743f563-d70f-4d31-8b79-5e5d1e325e82 {
  border-right: 1px solid black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.cs-827e3308-bc96-4f03-87db-e4c556ebb997 {
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  color: #222222;
}

.cs-a587e7f1-9191-419a-acf7-14aa4c0da873 {
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  color: #222222;
}

.cs-2e6f7ce0-d824-42cd-b925-478b3d877b47 {
  border-right: 1px solid black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.cs-346040d3-9b30-4554-b3b4-cbeeb93c8df5 {
  border-right: 1px dashed black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.cs-ace7d3a3-f567-40c6-b7e5-e2ff3d82d697 {
  display: none;
}

.cs-ace7d3a3-f567-40c6-b7e5-e2ff3d82d697-marked {
  display: flex;
  color: #222222;
}

.cs-b1f1d5d6-8e7f-4289-9397-308adc226cde {
  border-right: 1px dashed black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.cs-e49bb243-4880-4128-8a98-a6abbac8ce3a {
  border-right: 1px dashed black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.cs-df0550c1-7a69-4d8a-922b-88d3bf5ea914 {
  display: none;
}

.cs-df0550c1-7a69-4d8a-922b-88d3bf5ea914-marked {
  display: flex;
  color: #222222;
}

.cs-9593bfb5-8acd-4ffd-837c-506a8301ded3 {
  border-right: 1px solid black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.cs-1f756368-e33a-4bd4-8680-f54bcfe771dc {
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  color: #222222;
}

.cs-92428a99-215b-4450-a388-b359a867cb75 {
  border-right: 1px dashed black;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.cs-5d1b9fbc-655f-4877-b0b8-27a3ae9693ac {
  display: none;
}

.cs-5d1b9fbc-655f-4877-b0b8-27a3ae9693ac-marked {
  display: flex;
  color: #222222;
}

.cs-05c9a3a7-7310-4d34-843c-ea4f828a3d57 {
  color: #000;
}

.cs-681590ca-2ea0-45fa-99be-39ddcae18320 {
  color: #222222;
}

.cs-9a93e089-5e4e-4a3d-83a3-b66ed8b74f96 {
  border-right: 1px dashed rgba(0, 0, 0, 0.1411764706);
  width: 11rem;
  min-width: 11rem;
  max-width: 11rem;
  padding: 0.5rem;
  text-align: center;
}

.cs-9bd86cd6-aafd-455c-b319-cbf4ed8286e0 {
  color: #222222;
}

.cs-b5d9add4-c9f5-49e4-ba39-9463d3b2e4fd {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  max-width: 100vw;
}

.cs-5febc0d7-5e73-4826-a06c-b321a56ef2c0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cs-d59be20b-c53d-4326-83f3-c065f848f00c {
  text-align: center;
  color: #222222;
}

.cs-a3427727-a56b-41ad-bb99-ebf86ef7499f {
  color: #222222;
}

.cs-42a53266-8dd3-4cf1-b86f-57796556c9e3 {
  color: #222222;
}

.cs-f8ced8f4-96b2-441d-b708-97a6f4da63fd {
  display: flex;
  flex-direction: column;
}

.cs-e3067e79-d552-4ff6-a0c8-5331403a4663 {
  display: flex;
  flex-direction: row;
}

.cs-e3067e79-d552-4ff6-a0c8-5331403a4663:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.1411764706);
}

.cs-d97cb9e9-a870-47f0-a506-f0505d445a06 {
  width: 6rem;
  min-width: 6rem;
  max-width: 6rem;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.cs-803acb01-e0ff-4489-bfe1-4f0470f09c0d * {
  line-height: 1.5;
  font-size: 1rem;
}

.cs-96ce7568-f7f6-40d0-8557-41fda4c73624 {
  border-right: 1px dashed rgba(0, 0, 0, 0.1411764706);
  width: 11rem;
  min-width: 11rem;
  max-width: 11rem;
  padding: 0.5rem;
  text-align: center;
}

.cs-cf499834-f768-40d1-ac97-d3def8d7c336 {
  color: #222222;
  font-weight: bold;
}

.cs-8a1f6089-eb49-463b-ab4d-4387b8f95790 {
  border-right: 1px dashed rgba(0, 0, 0, 0.1411764706);
  width: 11rem;
  min-width: 11rem;
  max-width: 11rem;
  padding: 0.5rem;
  text-align: center;
}

.cs-bd4b36b5-7cfa-4de1-b897-6fb58b0ba630 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0 5rem;
}

.cs-8b01b120-ebe7-4ed2-8633-9bec07b017a8 {
  white-space: nowrap;
  font-weight: bold;
}

.cs-8b01b120-ebe7-4ed2-8633-9bec07b017a8 .trix-content {
  font-size: 1rem;
  line-height: 1;
}

.cs-c362a1c8-a9fc-498a-b420-853d82ae82f1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  text-decoration: none;
  color: white;
  background-color: #222222;
  font-size: 1rem;
  border: 2px solid #222222;
  border-radius: 6px;
  max-height: 2.5rem;
  transition: box-shadow 0.1s ease-in-out;
  cursor: pointer;
}

.cs-c362a1c8-a9fc-498a-b420-853d82ae82f1:focus, .cs-c362a1c8-a9fc-498a-b420-853d82ae82f1:hover {
  box-shadow: rgba(240, 46, 170, 0.4) -4px -4px, rgba(240, 46, 170, 0.3) -8px -8px, rgba(240, 46, 170, 0.2) -12px -12px, rgba(240, 46, 170, 0.1) -16px -16px, rgba(240, 46, 170, 0.05) -18px -18px;
  outline: none;
}

.cs-c362a1c8-a9fc-498a-b420-853d82ae82f1:focus:active {
  box-shadow: none;
  background: #f2f2f2;
  color: #222222;
  outline: none;
}

.cs-872f5195-dad3-44d0-ad7f-d07d514880f8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 5rem;
}

.XS .cs-872f5195-dad3-44d0-ad7f-d07d514880f8 {
  margin: 0 0 3rem;
}

.SM .cs-872f5195-dad3-44d0-ad7f-d07d514880f8 {
  margin: 0 0 3rem;
}

.MD .cs-872f5195-dad3-44d0-ad7f-d07d514880f8 {
  margin: 0 0 3rem;
}

.cs-c206cc74-50f4-4b96-8ce4-4c27b2d85eec {
  max-width: 70ch;
  color: #222222;
}

.cs-c206cc74-50f4-4b96-8ce4-4c27b2d85eec .trix-content {
  font-size: 1rem;
  text-align: justify;
  color: #222222;
  line-height: 1.5;
}

.XS .cs-c206cc74-50f4-4b96-8ce4-4c27b2d85eec {
  padding: 1rem;
}

.SM .cs-c206cc74-50f4-4b96-8ce4-4c27b2d85eec {
  padding: 1rem;
}

.cs-8f5ed7de-fc5d-4de9-84b9-be1d7201c6d9 .trix-content h1 {
  font-size: 1.563rem;
  margin: 3rem auto 1rem auto;
  line-height: 1.2;
  color: #222222;
  font-weight: bold;
}

.cs-beaed357-1ee6-4340-806c-ad8053b5ae38 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8vh 0 0;
}

.XS .cs-beaed357-1ee6-4340-806c-ad8053b5ae38 {
  margin: 8vh 0 2rem;
}

.SM .cs-beaed357-1ee6-4340-806c-ad8053b5ae38 {
  margin: 8vh 0 2rem;
}

.cs-70ffb178-88f5-40af-aaad-62e74c9b22b1 .trix-content h1 {
  font-size: 1.25rem;
  line-height: 1.4;
  color: #222222;
  font-weight: bold;
  margin: 0;
}

.XS .cs-70ffb178-88f5-40af-aaad-62e74c9b22b1 .trix-content h1 {
  font-size: 1.5rem;
}

.SM .cs-70ffb178-88f5-40af-aaad-62e74c9b22b1 .trix-content h1 {
  font-size: 1.5rem;
}

.cs-3490bbfb-2231-439b-b0f7-c4d4fc4e314a .trix-content {
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  color: #222222;
}

.cs-289dcb01-ecbe-4f70-9530-1ed0859ae611 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8vh 0 3rem;
}

.XS .cs-289dcb01-ecbe-4f70-9530-1ed0859ae611 {
  padding: 1rem;
}

.SM .cs-289dcb01-ecbe-4f70-9530-1ed0859ae611 {
  padding: 1rem;
}

.MD .cs-289dcb01-ecbe-4f70-9530-1ed0859ae611 {
  padding: 1rem;
}

.cs-e64d648c-2d1b-4e4c-bda9-6c81eb447701 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.cs-a23eee79-8ce9-4609-85fd-4317ff7da5a8 {
  max-width: 40rem;
  color: #222222;
}

.cs-a23eee79-8ce9-4609-85fd-4317ff7da5a8 .trix-content {
  font-size: 1rem;
  line-height: 1.5;
  text-align: justify;
  color: #222222;
  margin: 1rem 0;
}

.XS .cs-a23eee79-8ce9-4609-85fd-4317ff7da5a8 .trix-content {
  font-size: 1rem;
}

.SM .cs-a23eee79-8ce9-4609-85fd-4317ff7da5a8 .trix-content {
  font-size: 1rem;
}

.cs-b7f517ba-e531-4fb0-8704-b8aabde678b5 {
  display: flex;
  flex-direction: row;
  margin: 2rem auto 1rem auto;
}

.cs-416da769-3583-4d45-8557-15921a90e138 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.25rem;
  line-height: 1.4;
  padding-left: 0.3rem;
}

.XS .cs-416da769-3583-4d45-8557-15921a90e138 {
  font-size: 1.5rem;
}

.SM .cs-416da769-3583-4d45-8557-15921a90e138 {
  font-size: 1.5rem;
}

.cs-17aa9982-3a48-49b9-a86f-86f1c257bfe1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8vh 0 0;
}

.cs-64974092-9e47-4523-9f47-37b02c12a5ab .trix-content h1 {
  font-size: 1.563rem;
  margin: 3rem auto 1rem auto;
  line-height: 1.2;
  color: #222222;
  font-weight: bold;
}

.cs-b6b20af6-6f0c-4e8e-bef5-b65a867f3995 {
  display: none;
}

.cs-46e7efab-f0a5-4f2c-bf36-0c02d3ed8869 .trix-content {
  font-size: 1.5rem;
  color: #222222;
}

.XS .cs-46e7efab-f0a5-4f2c-bf36-0c02d3ed8869 .trix-content {
  font-size: 1rem;
}

.SM .cs-46e7efab-f0a5-4f2c-bf36-0c02d3ed8869 .trix-content {
  font-size: 1rem;
}

.cs-e8effb21-169f-43ae-bde4-4dccca3ff249 {
  margin-left: 1rem;
  color: black;
}

.cs-01de2be3-c381-4d62-a7d5-81d552aa0f95 {
  color: black;
}

.cs-65d7f337-67fd-4002-bd39-1f6b03424959 .trix-content {
  font-size: 1.5rem;
  color: #222222;
}

.XS .cs-65d7f337-67fd-4002-bd39-1f6b03424959 .trix-content {
  font-size: 1rem;
}

.SM .cs-65d7f337-67fd-4002-bd39-1f6b03424959 .trix-content {
  font-size: 1rem;
}

.cs-3b8eb00b-0807-4092-9d96-4a47d763a0ca {
  margin-left: 1rem;
  color: black;
}

.cs-f82503fd-1ece-480f-8e24-5d739f059fe4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  font-size: 1.5rem;
  line-height: 1.7;
}

.XS .cs-f82503fd-1ece-480f-8e24-5d739f059fe4 {
  flex-wrap: wrap;
}

.SM .cs-f82503fd-1ece-480f-8e24-5d739f059fe4 {
  flex-wrap: wrap;
}

.cs-e032a976-2ea8-4ccc-96b2-40c70aafef40 {
  margin-left: 1rem;
  color: black;
}

.cs-e2677bff-1558-4e1b-920d-4a23b823b3fd .trix-content {
  font-size: 1.5rem;
  color: #222222;
}

.XS .cs-e2677bff-1558-4e1b-920d-4a23b823b3fd .trix-content {
  font-size: 1rem;
}

.SM .cs-e2677bff-1558-4e1b-920d-4a23b823b3fd .trix-content {
  font-size: 1rem;
}

.cs-fc7594f9-9cbb-4877-b89a-f2318b9e5bda .trix-content {
  font-size: 1.5rem;
  color: #222222;
}

.XS .cs-fc7594f9-9cbb-4877-b89a-f2318b9e5bda .trix-content {
  font-size: 1rem;
}

.SM .cs-fc7594f9-9cbb-4877-b89a-f2318b9e5bda .trix-content {
  font-size: 1rem;
}

.cs-6cc749f8-8213-42fd-8848-498feb78b89a h1 {
  font-size: 1.953rem;
  margin: 4rem auto 2rem auto;
  line-height: 1.1;
  color: #222222;
  font-weight: bold;
}

.cs-504a374e-e1a7-46cc-86ea-4bc66c20c715 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20vh 0 3rem;
}

.XS .cs-504a374e-e1a7-46cc-86ea-4bc66c20c715 {
  margin: 10vh 0 3rem;
}

.SM .cs-504a374e-e1a7-46cc-86ea-4bc66c20c715 {
  margin: 10vh 0 3rem;
}

.cs-d2d14754-63db-4794-99b3-9f2aa61df91e {
  font-size: 1.563rem;
  margin: 0 auto 0 auto;
  line-height: 1.2;
  color: #222222;
}

.XS .cs-d2d14754-63db-4794-99b3-9f2aa61df91e {
  font-size: 1.2rem;
}

.SM .cs-d2d14754-63db-4794-99b3-9f2aa61df91e {
  font-size: 1.2rem;
}

.cs-90353ef8-8848-4d3b-b648-e14fe4e2bac8 {
  margin-top: 5rem;
  width: 100%;
  max-width: 10rem;
}

.cs-90353ef8-8848-4d3b-b648-e14fe4e2bac8 img {
  border: 2px solid #222222;
  border-radius: 6px;
  overflow: hidden;
}

.cs-45396b5e-ee09-4892-b8b8-6132704b53d2 {
  color: black;
  text-decoration: none;
}

.cs-8a0dcca7-c73e-4456-8548-2b9a3c6a3f61 h1 {
  font-size: 1.563rem;
  margin: 3rem auto 1rem auto;
  line-height: 1.2;
  color: #222222;
  font-weight: bold;
}

.cs-a977a9e8-7b54-4001-82c4-80f025bd304e {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 3rem;
}

.cs-19f3a129-5617-4f9a-a036-42fa87efe569 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.cs-8faeb46d-50aa-486a-998b-046a8717b65f {
  color: #222222;
  font-size: 1.25rem;
  letter-spacing: -0.012em;
  line-height: 1.5;
  margin: 0 0 5rem;
}

.XS .cs-8faeb46d-50aa-486a-998b-046a8717b65f {
  font-size: 1rem;
}

.cs-b0571b4f-6d59-4646-93fb-cfaad7a3ffd7 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.cs-5a68a463-4c96-4685-8902-d7c387846d60 {
  margin: 1.6rem 0;
  border-bottom: 2px solid #222222;
  max-width: 70ch;
  width: 100%;
}

.cs-26a57574-0c99-4a0b-844c-025ad3d01236 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 15rem;
  height: 15rem;
  padding: 7rem 0 7rem;
}

.cs-5a3480d4-d1ba-4ebd-a929-bd66a2427796 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cs-51e78f32-7877-412b-8fd2-c84b8df52be7 {
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  line-height: 1.4;
  color: #222222;
  text-align: center;
}

.cs-51e78f32-7877-412b-8fd2-c84b8df52be7 a {
  color: rgba(68, 115, 165, 0.8);
  text-decoration: none;
}

.cs-51e78f32-7877-412b-8fd2-c84b8df52be7 a:hover {
  background-color: #ecf8ff;
}

.cs-51e78f32-7877-412b-8fd2-c84b8df52be7 strong {
  white-space: nowrap;
}

.XS .cs-51e78f32-7877-412b-8fd2-c84b8df52be7 {
  font-size: 1.3rem;
  padding: 1rem 1rem 0;
}

.SM .cs-51e78f32-7877-412b-8fd2-c84b8df52be7 {
  padding: 1rem 1rem 0;
}

.cs-86e583ff-5694-47ad-ae09-46e3943ff8f4 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: #222222;
  font-weight: bold;
  font-size: 1.953rem;
  line-height: 1.1;
}

.cs-2cbbccbf-a554-4ddc-a5b1-e089b8b842bf {
  max-width: 70rem;
  width: 100%;
}

.cs-def85aa9-bc05-47c0-b63f-27421a036c1e {
  grid-column-start: 4;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 5;
}

.XS .cs-def85aa9-bc05-47c0-b63f-27421a036c1e {
  order: -2;
}

.SM .cs-def85aa9-bc05-47c0-b63f-27421a036c1e {
  order: -2;
}

.cs-6c7dc450-4106-4fd1-8036-a32462a247ba {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #182027;
  overflow: hidden;
  padding: 1rem 0 3rem;
}

.XS .cs-6c7dc450-4106-4fd1-8036-a32462a247ba {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SM .cs-6c7dc450-4106-4fd1-8036-a32462a247ba {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.XS .cs-c617cc1b-e28e-4d3b-b132-97c3a273a242 {
  grid-column-start: 4;
  grid-column-end: 13;
  grid-row-start: 5;
  grid-row-end: 15;
}

.SM .cs-c617cc1b-e28e-4d3b-b132-97c3a273a242 {
  grid-column-start: 4;
  grid-column-end: 13;
  grid-row-start: 5;
  grid-row-end: 15;
}

.MD .cs-c617cc1b-e28e-4d3b-b132-97c3a273a242 {
  grid-column-start: 4;
  grid-column-end: 13;
  grid-row-start: 5;
  grid-row-end: 15;
}

.LG .cs-c617cc1b-e28e-4d3b-b132-97c3a273a242 {
  grid-column-start: 4;
  grid-column-end: 13;
  grid-row-start: 5;
  grid-row-end: 15;
}

.XL .cs-c617cc1b-e28e-4d3b-b132-97c3a273a242 {
  grid-column-start: 4;
  grid-column-end: 13;
  grid-row-start: 5;
  grid-row-end: 15;
}

.XXL .cs-c617cc1b-e28e-4d3b-b132-97c3a273a242 {
  grid-column-start: 4;
  grid-column-end: 13;
  grid-row-start: 5;
  grid-row-end: 15;
}

.cs-45396b5e-ee09-4892-b8b8-6132704b53d2 {
  color: black;
  text-decoration: none;
}

.cs-8a0dcca7-c73e-4456-8548-2b9a3c6a3f61 h1 {
  font-size: 1.563rem;
  margin: 3rem auto 1rem auto;
  line-height: 1.2;
  color: #222222;
  font-weight: bold;
}

.cs-a977a9e8-7b54-4001-82c4-80f025bd304e {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 3rem;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local(""), url("../fonts/poppins/poppins-latin-200.woff2") format("woff2"), url("../fonts/poppins/poppins-latin-200.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local(""), url("../fonts/poppins/poppins-latin-200italic.woff2") format("woff2"), url("../fonts/poppins/poppins-latin-200italic.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("../fonts/poppins/poppins-latin-regular.woff2") format("woff2"), url("../fonts/poppins/poppins-latin-regular.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("../fonts/poppins/poppins-latin-italic.woff2") format("woff2"), url("../fonts/poppins/poppins-latin-italic.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("../fonts/poppins/poppins-latin-700.woff2") format("woff2"), url("../fonts/poppins/poppins-latin-700.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("../fonts/poppins/poppins-latin-700italic.woff2") format("woff2"), url("../fonts/poppins/poppins-latin-700italic.woff") format("woff");
}
.page {
  font-family: "Poppins", sans-serif;
}